<template>
	<div
		class="v-word"
		:class="{
			illustration: illustration && word.illustration,
			coverback,
			input,
			bigsize,
		}"
	>
		<div
			class="v-word__content mb-2 rounded"
			v-if="illustration && word.illustration"
			:class="{ without: currentLesson.without_translation }"
		>
			<div class="h-full">
				<!-- <img class="rounded background" v-if="word.type === 0 && coverback" :src="word.illustration" alt="">
        <video class="rounded background" v-else-if="word.type === 1 && coverback" :src="word.illustration" autoplay loop muted></video> -->

				<img
					class="rounded"
					v-if="word.type === 0"
					:src="word.illustration"
					alt=""
					:class="{
						opacity: isOpacity,
					}"
				/>
				<video
					disablePictureInPicture
					class="rounded"
					v-else-if="word.type === 1"
					:src="word.illustration"
					autoplay
					loop
					muted
					:class="{
						opacity: isOpacity,
					}"
				></video>
			</div>
		</div>

		<div v-if="audio" @click="playAudio" class="audio-button v-clickable cursor-pointer">
			<img src="@/assets/listen.png" />
		</div>

		<template v-if="isChinese">
			<div v-if="translate" v-html="word.translate"></div>
			<div v-else-if="cnSymbol" class="flex flex-wrap">
				<div
					v-for="(symbol, symbolIndex) in word.text.split('')"
					:key="symbolIndex"
					:id="`character-target-div-${word.id}-${symbolIndex}`"
					class="transparent"
				></div>
			</div>
			<div v-else v-html="word.text + (showPinyin && word.pinyin ? ' (' + word.pinyin + ')' : '')"></div>
		</template>
		<template v-else>
			<div
				class="v-word__word break-words text-xl text-center flex-grow flex items-center justify-center flex-col w-full adaptive-font-size"
				style="font-size: clamp(0.625rem, 0.5978rem + 0.8696vw, 1.25rem)"
				v-if="!translate || (translate && !currentLesson.without_translation)"
				v-html="
					translate ? word.translate : word.text + (showPinyin && word.pinyin ? ' (' + word.pinyin + ')' : '')
				"
			></div>
		</template>

		<template v-if="isChinese && symbolMode && input">
			<div class="flex flex-wrap justify-center my-auto pt-3">
				<div
					v-for="(symbol, symbolIndex) in word.text.split('')"
					:key="symbolIndex"
					:id="`character-target-div-${word.id}-${symbolIndex}`"
					class="mr-1 mb-1"
				></div>
			</div>
		</template>
		<template v-else-if="input">
			<div class="v-word__input mt-3">
				<input
					type="text"
					autocomplete="on"
					autocapitalize="on"
					autocorrect="on"
					spellcheck="true"
					style="display: none"
				/>
				<input
					@paste.prevent
					:placeholder="$t('games.enter_answer')"
					autocomplete="off"
					autocapitalize="off"
					spellcheck="false"
					autocorrect="off"
					v-model="inputData"
					@focus="$emit('focus')"
					ref="input"
					v-predioff
				/>
			</div>
		</template>

		<div
			v-if="needSpecialCharacter && input && focused && !symbolMode"
			class="flex justify-center items-center w-full"
		>
			<div class="flex flex-wrap justify-center mt-5 flex-grow">
				<div
					class="p-1"
					v-for="(char, charIndex) in getSpecialCharacter"
					:key="charIndex"
					@click="addChar(char)"
				>
					<div class="v-char-button v-clickable">{{ char }}</div>
				</div>
				<div class="p-1" @click="specialCaptalize = !specialCaptalize">
					<div class="v-char-button v-clickable">
						<img src="@/assets/whiteUp.png" alt="" :class="{ active: specialCaptalize }" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { stripHtml } from '../helpers'

import HanziWriter from 'hanzi-writer'
import { mapState } from 'vuex'
import { getCurrentInstance } from 'vue'

export default {
	props: {
		word: {
			type: Object,
			value: () => ({}),
		},
		illustration: {
			type: Boolean,
			default: true,
		},
		coverOpacity: {
			type: Boolean,
			default: true,
		},
		coverback: Boolean,
		translate: Boolean,
		without: Boolean,
		input: Boolean,
		wid: Number,

		cnAnimate: Boolean,
		cnSymbol: Boolean,
		cnPinyin: Boolean,
		bigsize: Boolean,
		audio: Boolean,
		showPinyin: {
			type: Boolean,
			default: false,
		},
		showTranslation: Boolean,
	},
	data: () => ({
		inputData: '',
		specialCaptalize: false,

		focused: false,
		chineseWriters: [],
		done: [],
		withError: false,
		instance: getCurrentInstance(),
	}),
	mounted() {
		this.setChineseAndAnimate()

		const textBlocks = document.querySelectorAll('.adaptive-font-size')
		textBlocks.forEach((textBlock, idx) => {
			const startFontSize = parseInt(getComputedStyle(textBlock).fontSize) // Получите размер шрифта
			let fontSize = startFontSize
			const containerWidth = textBlock.clientWidth
			let textScrollWidth = textBlock?.scrollWidth
			let hasScroll = containerWidth < textScrollWidth

			if (hasScroll) {
				while (hasScroll) {
					fontSize -= 1
					textBlock.style.setProperty('font-size', `${fontSize === 0 ? 1 : fontSize - 5}px`, 'important')

					textScrollWidth = textBlock.scrollWidth
					hasScroll = fontSize <= 0 ? false : containerWidth < textScrollWidth
				}
			}
		})
	},
	watch: {
		inputData(data) {
			this.$emit('input', { data, word: this.word })
		},
		async 'word.text'(data) {
			await this.$nextTick()
			this.setChineseAndAnimate()
		},
	},
	methods: {
		async playAudio() {
			if (this.word.audio) {
				const audioNode = new Audio()
				audioNode.src = this.word.audio

				try {
					await audioNode.play()
				} catch (err) {
					console.log('Error while playing audio', err)
				}
			}
		},
		/**
		 * Очистить все svg с ввода или показа символов
		 */
		resetAllChildNodes() {
			this.done = []
			document.querySelectorAll("[id*='character-target-div'] svg").forEach((node) => {
				node.remove()
			})
		},

		/**
		 * Запустить режим рисования иероглифа для всех отрисованых символов
		 */
		runChineseQuiz() {
			this.chineseWriters.forEach((e, i) => {
				e.quiz({
					onMistake: (strokeData) => {
						// if (strokeData.mistakesOnStroke >= 3) {
						//   this.withError = true;
						//   this.inputData = `{${this.word.text}}`;
						// }
					},
					onCorrectStroke: (strokeData) => {},
					onComplete: (summaryData) => {
						this.done.push(e)
						if (this.done.length === this.chineseWriters.length && !this.withError) {
							this.inputData = this.word.text
							// this.handleInput(true);
							// TODO: Finish
						}
					},
				})
			})
		},

		/**
		 * Отрисовать символы
		 */
		async setChineseAndAnimate() {
			if (this.input && !this.symbolMode) return

			if (this.isChinese && this.cnSymbol) {
				// Сброс старых
				this.resetAllChildNodes()
				await this.$nextTick()

				// Ширина символа от ширины контейнера, по умоланию
				let width = 300 / this.word.text.split('').length

				if (width > 100)
					width = 100 // Ширина не больше 100
				else if (width < 30) width = 50 // И не меньше 50

				// Если это тест - то 1/3 ~= 90
				if (this.input) {
					width = 140
				}

				this.chineseWriters = this.word.text.split('').map((e, i) => {
					return HanziWriter.create(`character-target-div-${this.word.id}-${i}`, e, {
						width: width,
						height: width,
						padding: 5,
						// strokeAnimationSpeed: 3,
						delayBetweenStrokes: 5,
						showCharacter: !this.input,
						showOutline: !this.input,
					})
				})

				// Анимировать в выучи слово
				if (this.cnAnimate) {
					setTimeout(() => {
						// Если длинна 2 - то анимируем по очереди
						if (this.chineseWriters.length === 2) {
							this.chineseWriters[0].animateCharacter({
								onComplete: () => {
									this.chineseWriters[1].animateCharacter()
								},
							})
						} else {
							// иначе - анимируем все сразу
							this.chineseWriters.forEach((w) => {
								w.animateCharacter()
							})
						}
					}, 500)
				}

				if (this.input && this.symbolMode) {
					this.runChineseQuiz()
				}
			}
		},
		setFocused(action) {
			this.focused = action
		},
		setInputData(value) {
			this.inputData = value
		},
		addChar(char) {
			const refs = this.instance.proxy?.$refs
			const value = this.$helpers.insertAtCursor(refs.input, char)
			this.inputData = value
		},
	},
	computed: {
		...mapState({
			currentLesson: (state) => state.lessons.currentLesson,
		}),

		isOpacity() {
			const translate = stripHtml(this.word.translate)
			const text = stripHtml(this.word.text)

			return this.coverOpacity && (this.translate ? translate : text)
		},
		symbolMode() {
			return !this.currentLesson.with_pinyin && this.isChinese
		},

		needSpecialCharacter() {
			return !!this.$helpers.specialSymbols[this.currentLesson.language]
		},

		isChinese() {
			return this.currentLesson.language === 'CN'
		},

		getSpecialCharacter() {
			const type = this.specialCaptalize ? 'uppercase' : 'lowercase'
			return this.$helpers.specialSymbols[this.currentLesson.language][type]
		},
	},
}
</script>

<style lang="scss" scoped>
.audio-button {
	position: absolute;
	top: 0;
	right: 0;
	height: 30px;
	width: 30px;
	min-width: 30px;
	background-color: #fff;
	border-radius: 50%;
	padding: 3px;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}
.v-word {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	width: 100%;
	height: 100%;
	position: relative;

	img {
		user-select: none;
	}

	&.illustration {
		justify-content: space-around;
	}

	&.input {
		justify-content: space-between;
	}

	&.input .v-word__word {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.bigsize .v-word__word {
		font-size: 25px;
	}
	&.bigsize .v-word__content img {
		max-height: 200px;
	}

	&__input {
		height: 40px;
		width: 100%;
		// padding: 2px 10px;
		border-top: 1px solid #757575;

		input {
			width: 100%;
			height: 100%;
			outline: none;
			text-align: center;
			font-family: 'Raleway-Medium' !important;
		}
		input::placeholder {
			text-align: center;
		}
	}

	&__content {
		max-height: 75%;
		// height: 65%;

		img,
		video {
			max-height: 150px;
			height: 100%;
			// width: 100%;
			object-fit: contain;
			margin: 0 auto;
		}

		&.without {
			height: 150px;
			max-height: 150px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: row;
		}
	}

	&.coverback &__content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
		max-height: unset;
		// position: relative;

		img,
		video {
			width: 100%;
			height: 100%;
			// object-fit: cover;
			max-height: unset;
			// opacity: 0.4;
			z-index: 1;
		}

		img.opacity,
		video.opacity {
			opacity: 0.4;
		}

		// img.background, video.background {
		//   position: absolute;
		//   top: 0;
		//   left: 0;
		//   width: 100%;
		//   height: 100%;
		//   max-height: unset;
		//   object-fit: cover;
		//   opacity: 0.1;
		//   z-index: 0;
		// }
	}

	&.coverback &__word {
		// font-family: 'Raleway-Medium' !important;
		text-shadow: 1px 1px 5px #fff;
	}

	&__word {
		// max-height: 30%;
		overflow: hidden;
		line-height: 1.2;
		z-index: 1;
		// word-break: break-words;
		font-family: 'Raleway-Medium' !important;
	}
}

[id*='character-target-div']:not(.transparent) {
	background-color: #f2f2f2;
	border-radius: 12px;
}
</style>
