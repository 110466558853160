<template>
  <div class="w-full flex justify-between my-10">
    <div class="flex items-center">
      <div class="v-avatar mr-5" v-if="!isProfile">
        <img :src="getAvatarUrl">
      </div>
      <div class="relative v-avatar-wrapper" v-else>
        <div class="v-avatar mr-5">
          <img :src="getAvatarUrl" id="pick-avatar">
          <input class="v-avatar__input" ref="file" type="file" accept="image/*" id="file-input" @change="handleFileUpload">
        </div>
        <div class="hidden lg:flex v-avatar__tooltip">{{ $t('home.upload') }}</div>
      </div>
      <div class="flex flex-col justify-around max-w-sm">
        <p class="v-text--title-bold user-row-height flex items-center">{{ profile.name }} {{ profile.surname }}</p>
        <p class="v-text--desc">{{ profile.school }} | {{ profile.city }}</p>
        <span></span>
      </div>
    </div>

    <div class="flex justify-between w-1/2" v-if="!statistic">
      <div class="w-2/3">
        <div class="flex items-center">
          <div class="mr-3">
            <div class="user-row-height flex items-center">
              <!-- <img class="user-row-height__icon mr-2" src="/images/logo-xxxhdpi.png" alt=""> -->
              <div class="v-button v-button__learned-words mr-2" @click="$router.push({ name: 'print' })">
                <icon-display iconPath="/images/new/dictionary.svg"/>
              </div>
              <div class="user-row-height__count">{{ profile.learned_word }}</div>
            </div>
            <div class="learnt-row-desc v-text--desc">{{ $t('home.the_learnt_words') }}</div>
          </div>
        </div>
      </div>

      <div class="w-3/5" v-if="!statistic">
        <div class="user-row-height flex items-center">
          <ProgressBar :percent="getLessonsProgressPercent" type="done"/>
        </div>
        <div class="v-text--desc">
          {{ $t('home.completed_out', { p1: profile.lessons_complete, p2: profile.lessons_count }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar.vue';
import AvatarCropper from "vue-avatar-cropper"
import IconDisplay from '@/components/IconDisplay.vue'
import { mapState, mapActions } from 'vuex';

export default {
  components: { ProgressBar, AvatarCropper, IconDisplay },
  props: {
    statistic: Boolean,
    isProfile: Boolean,
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile
    }),
    getAvatarUrl() {
        return this.profile?.avatar_150x150 || new URL(`../assets/avatar_placeholder.png`, import.meta.url).href;
    },
    getLessonsProgressPercent() {
      return (this.profile.lessons_complete * 100 / this.profile.lessons_count) || 0;
    },
  },
  methods: {
    ...mapActions(['UPDATE_STUDENT_INFO', 'GET_PROFILE_DATA']),
    async handleFileUpload(evt) {
      const file = evt.target.files[0];
      if (!file) return;

      try {
        const fillable = ['name', 'surname', 'email', 'city', 'school'];

        const fd = new FormData();

        // Берем из обновленных
        for (const key of fillable) {
          fd.append([key],this.profile[key]);
        }

        fd.append('file', file);
        fd.append('_method', 'PUT');

        await this.UPDATE_STUDENT_INFO(fd);
        await this.GET_PROFILE_DATA();
      } catch (err) {
        this.handleError();
      }
    },
    handleError() {
      this.$root.$emit('v-modal', {
        error: true,
        text: 'Превышен максимальный размер файла <br> Размер файла не должен превышать 10мб.',
        size: 'md'
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.v-avatar-wrapper {

  &:hover .v-avatar__tooltip:not(.hidden) {
    opacity: 1;
    visibility: visible;
  }
}

.v-avatar {
  min-width: 70px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid #fff;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &__tooltip {
    display: flex;
    justify-content: center;
    background-color: #00a447;
    position: absolute;
    top: 80px;
    left: 40%;
    transform: translateX(-50%);
    border-radius: 15px;
    margin: 0 auto;
    padding: 9px 16px;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    z-index: 10;

    &::before {
      content: '';
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 10px solid #00a447;
    }
  }
}

.user-row-height {
  height: 42px;

  &__icon {
    height: 100%;
  }

  &__count {
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
  }
}
.learnt-row-desc {
  margin-left: 52px;
  white-space: nowrap;
}
</style>
