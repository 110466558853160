<template>
	<div :class="[level === 1 ? 'tree-node' : '', { reset: group.isOpened && level > 1 }]">
		<div
			v-if="group"
			:class="level > 1 ? 'unit-node' : ''"
			class="tree-node__wrapper unit-node__wrapper flex items-center justify-between cursor-pointer"
			@click="openFolder"
		>
			<div class="flex items-center">
				<div class="relative mr-3" v-if="level === 1">
					<img class="folder-mini-icon h-8" src="@/assets/FolderBlueCard.png" alt="" />
					<span class="icon-status-plus">{{ group.isOpened ? '-' : '+' }}</span>
				</div>
				<div class="group-name">{{ group.name }}</div>
			</div>
			<img
				class="arrow-expanded"
				:class="{ horizontal: !group.isOpened }"
				src="@/assets/arrow_expanded.png"
				alt=""
			/>
		</div>

		<div v-if="group.isOpened && group.childs" class="mt-3 w-full" :class="'lg:pl-8'">
			<template v-if="group.view_type === 0 || group.marathon_status !== 'off'">
				<FolderTreeNode
					class="mb-2"
					v-for="folder in group.childs.filter((el) => el.data_type === 'folder')"
					:key="`folder-${folder.id}`"
					:group="folder"
					:level="level + 1"
				/>
				<div v-if="hasLessons" class="w-full rounded-xl" style="background: #fff">
					<div class="mb-2" v-for="lesson in getLessons" :key="`lesson-${lesson.id}`">
						<FolderLesson :lesson="lesson" :group="group" />
					</div>
				</div>
			</template>
			<FolderSection
				v-else-if="!!currentSections.length"
				class="mb-2"
				v-for="section in currentSections"
				:key="`folder-section-${section.id}`"
				:section="section"
				:group="group"
			/>

			<!-- <div v-if="group.childs.length > 0 && group.meta && group.meta.current_page < group.meta.last_page" style="width: 100px; height: 100px; background: red"></div>
				  <div v-else style="width: 100px; height: 100px; background: green"></div> -->
			<infinite-loading
				@infinite="onScrollBottom"
				v-if="group.childs.length > 0 && group.meta && group.meta.current_page < group.meta.last_page"
			>
				<div slot="spinner">
					<div class="w-full flex justify-center">
						<loader />
					</div>
				</div>
				<div slot="no-more"></div>
				<div slot="no-results"></div>
			</infinite-loading>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FolderLesson from './FolderLesson.vue'
import FolderSection from './FolderSection.vue'
import cloneDeep from 'lodash/cloneDeep'

export default {
	name: 'FolderTreeNode',
	components: { FolderLesson, FolderSection },
	props: {
		level: {
			type: Number,
			require: true,
		},
		group: {
			type: Object,
		},
	},
	computed: {
		...mapState({
			sections: (state) => state.folders.sections,
		}),
		state() {
			return state
		},
		isTrueMobile() {
			return window.isTrueMobile
		},
		currentSections() {
			if (!this.sections || this.sections.length === 0) return []
      console.log(this.sections)
			return this.sections
				.find((section) => section[0] === this.group.id)[1]
				.sort((a, b) => (a.order > b.order ? 1 : -1))
		},
		getLessons() {
			const lessons = cloneDeep(this.group.childs.filter((el) => el.data_type === 'lesson'))
			if (this.group.marathon_status !== 'as_you_progress' || lessons.length === 1) {
				return lessons
			}

			// let prevLesson = lessons[0]
			//
			// for (let i = 1; i < lessons.length; i++) {
			//   if (prevLesson.type === 'challenge' && prevLesson.is_published_post === true) {
			// 	  lessons[i].availability = true
			//   }
			//   prevLesson = lessons[i]
			// }

			return lessons
		},
		hasLessons() {
			return !!this.getLessons.length
		},
	},
	watch: {
		'group.isOpened'(value) {
			if (value) {
				// this.$store.commit('SET_BUSY_STATE', true)
				if (Number(this.group.view_type) === 1 && this.group.marathon_status === 'off') {
					this.GET_SECTIONS({ groupId: this.group.id })
				} else {
					this.LOAD_FOLDER_DATA({
						folderId: this.group.folder_id || this.group.id,
						isGroup: !this.group.folder_id,
						structure: this.group.structure,
					})
				}
				// this.$store.commit('SET_BUSY_STATE', false)
			}
		},
	},
	methods: {
		...mapActions(['OPEN_FOLDER', 'LOAD_FOLDER_DATA', 'GET_SECTIONS']),

		async openFolder() {
			// this.$store.commit('SET_BUSY_STATE', true)
			await this.OPEN_FOLDER({
				folderId: this.group.folder_id || this.group.id,
				isGroup: !this.group.folder_id,
				isTrueMobile: this.isTrueMobile,
				level: this.level,
			})
			// this.$store.commit('SET_BUSY_STATE', false)
		},

		async onScrollBottom($state) {
			console.log('Group meta', this.group.meta)

			if (!this.group.meta) {
				$state.loaded()
				return
			}

			if (this.group.meta.current_page < this.group.meta.last_page) {
				await this.LOAD_FOLDER_DATA({
					folderId: this.group.folder_id || this.group.id,
					isGroup: !this.group.folder_id,
					nextPage: true,
					structure: this.group.structure,
				})
				$state.loaded()
			} else if (this.group.meta.current_page === this.group.meta.last_page) {
				$state.complete()
			} else {
				$state.loaded()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.folder-mini-icon {
	width: 32px;
	height: 32px;
	min-width: 32px;
}

img.horizontal {
	transform: rotate(-90deg);
}

.arrow-expanded {
	opacity: 0.75;
	transition: all 0.2s ease;
}

.icon-status-plus {
	position: absolute;
	color: #fff;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 18px;
	font-weight: bold;
}

.unit-node {
	background-color: #fff;
	padding: 10px 10px;
	border-radius: 30px;
	color: #404040;
	font-size: 16px;

	@media screen and (max-width: 1024px) {
		background-color: #fbfbfb;
	}

	&:hover {
		box-shadow: 0px 5px 30px 0px rgba(0, 174, 239, 0.1);
	}

	&.reset {
		background-color: transparent;

		&:hover {
			box-shadow: none;
		}
	}
}

.group-name.reset {
	font-size: 20px;
	font-weight: bold;
}

.tree-node {
	border-radius: 30px;
  background: linear-gradient(135deg, #fff, #fafbfc 51.82%, #f5f7fa);
  box-shadow: 0 1px 1px #090d140f, -4px -4px 8px #fff6, 8px 8px 24px #090d140f;
	padding: 10px 20px;
	color: #404040;

	&:hover {
    background: linear-gradient(
            135deg,
            #f9fdff 0%,
            #ebf7ff 51.82%,
            #f3faff 100%
    ),
    linear-gradient(135deg, #ffffff 0%, #fafbfc 51.82%, #f5f7fa 100%);
	}

	@media screen and (max-width: 1024px) {
		padding: 5px 10px;
	}
}

.tree-node,
.unit-node {
	&:hover .arrow-expanded {
		opacity: 1;
	}
}
</style>
