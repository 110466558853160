<template>
	<transition name="fade">
		<div class="v-result" v-if="show && !isNovakid">
			<div class="v-result__wrapper">
				<img
					v-if="isTeacher"
					class="v-result__close v-clickable"
					src="@/assets/close-xxxhdpi.png"
					alt=""
					@click="show = false"
				/>
				<div class="v-result__title text-center text-lg v-text--bold mb-5">
					{{ $t('games.test_results') }}
				</div>

				<div
					v-if="!haveOpenQuestion"
					class="v-result__description v-text--bold mb-10 text-center"
					:style="{ color: getPercentStyles.color }"
				>
					{{ getPercentStyles.header }}
				</div>
				<div class="v-result__container w-full flex flex-col items-center justify-center mb-5">
					<template v-if="!haveOpenQuestion">
						<BrainLogoControlled :percentage="getPercents" />
						<div class="v-text--bold text-2xl text-center mt-2">{{ getPercents || 0 }}%</div>
						<div class="v-text--gray text-md text-center">{{ resultText }}</div>
					</template>
					<template v-else>
						<div class="v-text--bold text-xl text-center my-3">{{ $t('lesson.result_after_check') }}</div>
					</template>

					<template v-if="!haveOpenQuestion">
						<div v-if="getPercents >= 100">
							<img class="v-result__icon" src="@/assets/emoji/good_up.png" alt="" />
							<img class="v-result__icon" src="@/assets/emoji/good_stars.png" alt="" />
							<img class="v-result__icon" src="@/assets/emoji/good_stars.png" alt="" />
							<img class="v-result__icon" src="@/assets/emoji/good_up.png" alt="" />
						</div>
						<div v-else-if="getPercents >= 90">
							<img class="v-result__icon" src="@/assets/emoji/good_up.png" alt="" />
							<div class="v-result__icon" />
							<div class="v-result__icon" />
							<img class="v-result__icon" src="@/assets/emoji/good_up.png" alt="" />
						</div>
						<div v-else-if="getPercents >= 50">
							<img class="v-result__icon" src="@/assets/emoji/middle_ok.png" alt="" />
							<div class="v-result__icon" />
							<div class="v-result__icon" />
							<img class="v-result__icon" src="@/assets/emoji/middle_ok.png" alt="" />
						</div>
						<div v-else>
							<div class="v-result__icon" />
							<img class="v-result__icon" src="@/assets/emoji/bad_down.png" alt="" />
							<img class="v-result__icon" src="@/assets/emoji/bad_down.png" alt="" />
							<div class="v-result__icon" />
						</div>
					</template>
				</div>
				<div class="flex my-5 items-start justify-center flex-aligncenter" v-if="showRepeat && !timerEnd && !haveOpenQuestion">
					<IconDisplay iconPath="/images/comment.svg" customClass="v-result__comment"/>
					<div class="v-text--gray text-sm text-left ml-3">{{ getPercentStyles.centerText }}</div>
				</div>
				<div class="w-full flex justify-center my-3" v-if="showErrors && !haveOpenQuestion && !isTrueMobile">
					<div class="vzn-button" @click="handleShowErrors">
						{{ $t('lesson.show_mistakes') }}
					</div>
				</div>

				<div class="v-result__divider my-2 lg:my-5" v-if="assesmentPercents === null"></div>

				<div class="flex v-exp" v-if="assesmentPercents === null && learnedCount">
					<div class="v-exp__title">{{ $t('games.experience') }}:</div>
					<div class="v-exp__exp">+{{ learnedCount }} {{ $t('games.new_words') }}</div>
				</div>

				<div class="v-result__divider my-2 lg:my-5" v-if="assesmentPercents === null"></div>

				<div class="flex v-result__buttons" v-if="isProfileLoaded">
					<div class="v-result__button" v-if="getPercents < 100 && showRepeat && !timerEnd && canPassOneMore">
						<div class="v-result__button__one vzn-button-gradient" @click="handleImprove">
							<div>{{ $t('games.improve_the_result') }}</div>
						</div>
					</div>
					<div class="v-result__button">
						<div class="v-result__button__one vzn-button-blue" @click="handleButtonFinish">
							<div>{{ $t('games.finish_the_lesson') }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<FinalModalNovakid v-else-if="show && isNovakid" :count="rating" @close="onNextClick" @next="onNextClick" />
	</transition>
</template>

<script>
import BrainLogoControlled from '../BrainLogoControlled.vue'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { getSlug, getOnlypreview } from '../../helpers'
import FlutterBridge from '../../helpers/flutterBridge'
import IconDisplay from '@/components/IconDisplay.vue'

import flatten from 'lodash/flatten'
import FinalModalNovakid from './FinalModalNovakid/FinalModalNovakid.vue'
import FlutterNovakidBridge from '../../helpers/flutterNovakidBridge'
import useEventBus from '../../composables/useEventBus.js'
import { useRoute, useRouter } from 'vue-router'
import { defineEmits } from 'vue'

export default {
	components: { FinalModalNovakid, BrainLogoControlled, IconDisplay },
	data() {
		return {
			showErrors: false,
			rating: 0,
			show: false,
			params: {
				closeButton: true, // Показать крестик в углу
				closeOutside: true, // Закрыть модалку при нажатии за пределами окна
				text: 'Взнания', // Текст модалки
			},
			countWords: 0,
			countMissed: 0,
			assesmentPercents: null,
			lexicalPercent: null,
			timerEnd: false,
			haveOpenQuestion: false,
			learnedCount: 0,
		}
	},
	computed: {
		...mapGetters(['isNovakid', 'isNewMobileApp']),
		...mapState({
			currentLesson: (state) => state.lessons.currentLesson,
			currentTestProgressData: (state) => state.lessons.currentTestProgressData,
			currentWords: (state) => state.lessons.currentWords,
			profile: (state) => state.user.profile,
		}),
		minTaskPercent() {
			if (
				!this.currentLesson ||
				!this.currentLesson.additional_info ||
				!this.currentLesson.additional_info.minTaskPercent
			)
				return 90
			return parseInt(this.currentLesson.additional_info.minTaskPercent, 10)
		},
		isTrueMobile() {
			return window.isTrueMobile
		},
		canPassOneMore() {
			return (
				this.currentLesson.type === 'video' ||
				!this.currentLesson.pass_once ||
				this.currentLesson.pass_once_current < this.currentLesson.pass_once
			)
		},
		symbolMode() {
			return !this.currentLesson.with_pinyin
		},
		isChinese() {
			return this.currentLesson.language === 'CN'
		},
		getTransProp() {
			return this.currentLesson.with_pinyin ? 'pinyin' : 'text'
		},
		...mapGetters(['getNotDoneTask', 'isProfileLoaded', 'isTeacher', 'getPlatform']),
		getLearnedLength() {
			return this.countWords - this.countMissed
		},
		isCompleted() {
			return this.getPercents >= 50
		},
		getPercents() {
			// const percentStep = 100 / this.countWords;
			return this.assesmentPercents || this.lexicalPercent || 0
		},
		showRepeat() {
			if (!this.assesmentPercents) return true
			if (
				this.timerEnd ||
				(this.assesmentPercents &&
					this.currentLesson.pass_once !== 0 &&
					this.currentLesson.pass_once_current >= this.currentLesson.pass_once)
			)
				return false
			if (
				this.assesmentPercents &&
				(!this.currentLesson.pass_once || this.currentLesson.pass_once_current < this.currentLesson.pass_once)
			)
				return true
		},
		getPercentStyles() {
			let result = {}

			if (this.getPercents < 50) {
				result = {
					header: this.$t('games.you_can_do_it_better'),
					centerText: this.$t('games.to_improve_the_result'),
					color: '#ce6d49',
				}
			}

			if (this.getPercents >= 50) {
				result = {
					header: this.$t('games.good_but'),
					centerText: this.$t('games.to_improve_the_result'),
					color: '#4695f7',
				}
			}

			if (this.getPercents >= 90) {
				result = {
					header: this.$t('games.well_done'),
					centerText: this.$t('games.if_you_get'),
					color: '#00a447',
				}
			}

			if (this.getPercents > 99) {
				result = {
					header: this.$t('games.perfect'),
					centerText: this.$t('games.you_are_the_best'),
					color: '#00a447',
				}
			}

			if (this.timerEnd) {
				result.header = this.$t('games.oops_time_is_up')
			}

			return result
		},
		resultText() {
			if (this.timerEnd) {
				return this.$t('games.your_result')
			}

			return this.isCompleted ? this.$t('games.you_did_it') : this.$t('games.try_again')
		},
	},
	setup() {
		const emitter = useEventBus()
		const emit = defineEmits(['showErrors', 'improve', 'navigate'])
		const route = useRoute()
		const router = useRouter()

		return {
			emitter,
			emit,
			route,
			router,
		}
	},
	created() {
		this.emitter.on('v-assessment-result', async (percents, timerEnd, haveOpenQuestion, showErrors, ratingInfo) => {
			this.showErrors = showErrors
			this.assesmentPercents = percents
			this.haveOpenQuestion = haveOpenQuestion
			this.timerEnd = timerEnd
			this.show = true

			await this.$nextTick()
			setTimeout(() => {
				document.activeElement && document.activeElement.blur()
			}, 100)

			if (ratingInfo && typeof ratingInfo === 'object' && ratingInfo.hasOwnProperty('rating')) {
				this.rating = ratingInfo?.rating
			}

			FlutterBridge.callService('FlutterLessonChannel', 'landscape_exit')
			// return window.FlutterLessonChannel.postMessage('landscape_exit');
		})

		this.emitter.on('v-test-result', async (resultPayload) => {
			this.countWords = this.currentTestProgressData.length
			// const missed = this.currentTestProgressData.filter(({ text, pinyin, userWord }) => {
			//   const word = this.getTransProp === 'text' ? text : pinyin;
			//   return this.$helpers.normalizeWord(word) !== this.$helpers.normalizeWord(userWord);
			// })

			// const answers = this.currentTestProgressData.map(({ id, text, pinyin, userWord }) => {
			//   return {
			//     id,
			//     text: userWord,
			//   }
			// })

			// this.countMissed = missed.length;

			if (this.isProfileLoaded || getOnlypreview()) {
				let data = null
				try {
					data = await this.STAGE_LEXICAL_RESULT({
						onlypreview: getOnlypreview(),
						lesson_id: this.currentLesson.id,
						group_id: this.route.query.group_id,
						answers: flatten(resultPayload),
						// test_type: 'write_word'
					})
				} catch (err) {
					return this.emitter.emit('v-modal', {
						text: `Ошибка отправки данных`,
					})
				}

				if (!data) return

				if (data && data.result) {
					const { result, mistakes_in_words, learned_words } = data.result
					this.lexicalPercent = result
					this.countMissed = mistakes_in_words.filter((el) => !el.credited).length
					this.learnedCount = learned_words.length

					if (
						data?.hasOwnProperty('rating_info') &&
						typeof data?.rating_info === 'object' &&
						data?.rating_info?.hasOwnProperty('rating')
					) {
						this.rating = data?.rating_info?.rating
					}
				}

				if (this.getPercents < this.minTaskPercent && !getOnlypreview()) {
					await this.RESET_LESSON_PROGRESS()
				}
			} else if (!getOnlypreview()) {
				const slug = getSlug()

				if (slug && !this.isTeacher) {
					this.emitter.emit('auth', { forTestTitle: true })
					return
				}
			}

			this.show = true

			await this.$nextTick()
			setTimeout(() => {
				document.activeElement && document.activeElement.blur()
			}, 100)
		})
	},
	beforeUnmount() {
		// this.emitter.off('v-test-result')
	},
	methods: {
		...mapActions(['STAGE_LEXICAL_RESULT', 'RESET_LESSON_PROGRESS', 'RESET_TEST_PROGRESS', 'GET_PROFILE_DATA']),
		...mapMutations(['RESET_TEST_PROGRESS']),

		handleShowErrors() {
			this.emit('showErrors')
			this.show = false
		},
		onNextClick() {
			if (!FlutterNovakidBridge.isFlutterApp()) {
				// IF SPA AND NOT FLUTTER NOVAKID APP
				this.handleButtonFinish()
			} else {
				// IF NOVAKID APP
				FlutterNovakidBridge.callService('FlutterGoBack', 'back')
			}
		},
		async handleButtonFinish() {
			if (FlutterBridge.isFlutterApp() && this.isNewMobileApp) {
				FlutterBridge.callService('FlutterGoBack', 'back')
			} else {
				this.router.push({ name: 'home' })
				this.show = false
				await this.GET_PROFILE_DATA(true) // Without messages count request
			}
		},
		async handleImprove() {
			this.show = false

			this.emit('improve')

			if (this.assesmentPercents !== null) {
				this.assesmentPercents = null
				return
			}

			if (this.getPercents >= this.minTaskPercent && this.getPercents < 100) {
				// Перезагрузить тест
				// Перезагрузить тест
				await this.RESET_TEST_PROGRESS()
			} else if (this.getPercents < this.minTaskPercent) {
				// Сбросить все и перейти к заданию
				// await this.RESET_LESSON_PROGRESS();
				this.emit('navigate', this.getNotDoneTask)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.v-exp {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;

	&__title {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}

	&__exp {
		background-color: #009ee0;
		padding: 5px 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		border-radius: 6px;
	}
}

.v-result {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($color: #000000, $alpha: 0.7);

	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10002;

	&__divider {
		border-bottom: 2px solid #757575;
		height: 1px;
		width: 100%;
		opacity: 0.3;
	}

	&__icon {
		position: absolute;
		width: 45px;

		&:nth-child(1) {
			top: 0;
			left: 5%;
			width: 35px;
			transform: rotate(-45deg);
		}

		&:nth-child(2) {
			top: 0;
			right: 10%;
			width: 25px;
			transform: rotate(45deg);
		}

		&:nth-child(3) {
			bottom: 25%;
			left: 10%;
			width: 35px;
			transform: rotate(45deg);
		}

		&:nth-child(4) {
			bottom: 25%;
			right: 5%;
			width: 25px;
			transform: rotate(-45deg) scale(-1, 1);
		}
	}

	&__container {
		position: relative;
	}

	&__title {
		position: static;
		top: 10px;
		left: 10px;
	}

	&__wrapper {
		width: 450px;
		// min-height: 600px;
		background-color: #fff;
		// background: url(/images/modal-pattern.png);
		background-size: cover;
		border-radius: 12px;
		padding: 50px 25px 10px;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		overflow: hidden;

		opacity: 0;
		z-index: 1;
		animation: showup 0.3s ease;
		animation-fill-mode: forwards;

		div::before {
			content: '';
			// background: url(/images/modal-pattern.png);
			opacity: 0.1;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			position: absolute;
			z-index: -1;
			pointer-events: none;
		}

		@keyframes showup {
			0% {
				opacity: 0;
				transform: scale(0.5);
			}

			100% {
				opacity: 1;
				transform: scale(1);
			}
		}

		@media (max-width: 1024px) {
			& > div:not(.v-result__buttons) {
				zoom: 0.7;
			}
		}
	}

	&__description{
		font-size: 27px;
		line-height: 27px;
	}

	&__comment{
		min-width: 16px;
		display: block;
		max-width: 16px;
	}

	&__buttons{
		gap: 10px;
		width: 100%;
	}

	&__button{
		width: 100%;
		display: flex;

		&__one{
			line-height: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;

			& div{
				width: 100%;
			}
		}
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 25px;
		cursor: pointer;
	}

	.v-result__wrapper {
		overflow-y: auto;
		padding: 20px;
	}
}
</style>
