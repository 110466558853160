<template>
  <FindWord
      :useTranslate="true"
      :noFilter="noFilter"
      :test="test"
      @done="$emit('done')"
      :fromAssessment="fromAssessment"
      :question="question"
      :index="index"
      ref="findWord"
  />
</template>

<script setup>
import FindWord from '@/screens/games/FindWord/index.vue';
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { preparedLessonForAssessment } from '@/helpers.js'

const props = defineProps({
  noFilter: Boolean,
  test: Boolean,
  fromAssessment: Boolean,
  question: {},
  index: {
    type: Number,
    default: 0,
  },
});

const store = useStore()

const GAME_SLUG = 'find_definition'

const currentLessonProgressData = computed(() => store.getters.getCurrentLessonProgressData(GAME_SLUG))
const currentWords = computed(() => (props.fromAssessment ? preparedWords.value : store.state.lessons.currentWords))
const preparedWords = computed(() => preparedLessonForAssessment(props.question))

const findWord = ref()
const isInvalid = ref(false)
const emit = defineEmits(['done', 'v-task-result', 'handleDraft', 'clear-error', 'set-error'])

const getTestData = () => {
	isInvalid.value = false
	emit("clear-error", props.question.id);

	const valid = currentLessonProgressData.value.words?.length === currentWords.value.length

	if (!valid) {
		isInvalid.value = true
		emit("set-error", props.question.id);
	}

	const data = findWord.value.getTestData()
	return {
		...data,
		type: 'lexical_find_a_value',
	}
}

defineExpose({ getTestData })

</script>

<style lang="scss" scoped>

</style>
