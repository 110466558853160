<template>
	<div class="container notifications-container mx-auto mt-5">
		<div v-if="!isLoading && notifications.length > 0">
			<div class="flex justify-end mb-5">
				<div class="v-text--desc link mr-5" @click="readAll">{{ $t('home.read_all') }}</div>
				<div class="v-text--desc link mr-5" @click="clearAll">{{ $t('home.remove_all') }}</div>
			</div>
			<div class="notify-row" v-for="(notify, index) in notifications" :key="index">
				<div class="close" @click="removeNotify(notify)"></div>
				<IconDisplay :iconPath="getNotifyIcon(notify.type)" customClass="h-8 w-8 icon-blue" />
				<div
					class="w-9/12 link"
					v-if="notify.type === 'marathon_hit_the_top' || notify.type === 'marathon_dropped_top'"
					@click="$router.push({ name: 'marathon' })"
				>
					{{ notify.text }}
				</div>
				<div class="w-9/12" v-else-if="notify.type === 'link_webinar'" @click="toWebinar(notify)">
					Ваш учитель {{ notify.additional_info.teacher }} начал видеоурок, кликните по уведомлению чтобы
					присоединиться.
				</div>
				<div class="w-9/12" v-else>
					{{ notify.text }}
					<span
						:class="{
							'ml-1': true,
							'v-link': true,
							disabled: !Boolean(notify.additional_info.availability),
						}"
						v-if="notify.type === 'create_lesson' && notify.additional_info"
						@click="openLesson(notify)"
					>
						открыть
					</span>
				</div>
				<div>{{ getNotifyDate(notify.created_at) }}</div>
			</div>
			<pagination class="my-5" @getEmit="onPageChange" :hide-url="true" ref="pager" />
		</div>
		<div v-else-if="isLoading" class="fullpage-noheader flex justify-center items-center">
			<loader />
		</div>
		<div v-else class="fullpage-noheader flex justify-center items-center">
			{{ $t('home.no_notifications') }}
		</div>
	</div>
</template>

<script setup>
import { useStore } from 'vuex'
import { getNotifications, readNotifications, removeNotifications } from '../api/user'
import Pagination from '../components/Pagination.vue'
import { computed, defineAsyncComponent, nextTick, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import serviceIcon from '@/assets/Header/service.svg'
import marathonIcon from '@/assets/Header/marathon.svg'
import { getNotifyDate } from '../../resources/helpers'
import IconDisplay from '@/components/IconDisplay.vue';

import moment from 'moment'
const page = ref(1)
const searchFilter = reactive({
	perPage: 20,
})
const notifications = ref([])
const isLoading = ref(false)
const store = useStore()
const router = useRouter()
const route = useRoute()
const pager = ref(null)

const getPlatform = computed(() => store.getters.getPlatform)
const profile = computed(() => store.getters.profile)

const readAll = async () => {
	await readNotifications('all')
	store.commit('SET_NOTIFICATIONS_COUNT', 0)
}

const toWebinar = (notify) => {
	let webinarUrl = process.env.WEBINAR_URL

	if (
		getPlatform &&
		getPlatform.webinar_url &&
		getPlatform.webinar_url.replace('http://', '').replace('https://', '')
	) {
		webinarUrl = getPlatform.webinar_url
	}

	document.location.replace(
		webinarUrl + `/b/?token=${localStorage.getItem('token')}&group=${notify.additional_info.id}`
	)
}

const clearAll = async () => {
	await removeNotifications('all')
	loadNotifications()
}

const removeNotify = async (notify) => {
	await removeNotifications([notify.id])
	loadNotifications()
}

const openLesson = (notify) => {
	if (!(notify.additional_info && notify.additional_info.availability)) return

	store.commit('CLEAR_CURRENT_LESSON_FORCE')
	let routeType = notify.additional_info.type === 'lexical' ? 'lesson' : notify.additional_info.type
	router.push({
		name: routeType,
		params: { id: notify.additional_info.lesson_id },
		query: { ...route.query, group_id: notify.additional_info.group_id },
	})
}

const getNotifyIcon = (type) => {
	switch (type) {
		case 'marathon_hit_the_top':
			return '/images/Header/marathon.svg'
		case 'marathon_dropped_top':
			return '/images/Header/marathon.svg'
		case 'service':
			return '/images/Header/service.svg'
		default:
			return '/images/Header/service.svg'
	}
}

const onPageChange = (i, perPage) => {
	page.value = i
	loadNotifications()
}

const loadNotifications = async () => {
	isLoading.value = true
	const { data } = await getNotifications({ page: page.value, filter: searchFilter })
	notifications.value = data.data

	// Mark as read
	let unreadIds = notifications.value.filter((notify) => notify.read_at === null).map((notify) => notify.id)
	await read(unreadIds)

	isLoading.value = false

	await nextTick(() => {
		if (pager.value && typeof pager.value.pageGenerate === 'function') {
			pager.value.pageGenerate(pageNumber, data.meta.last_page, searchFilter.perPage);
		}
	});
}

const read = async (ids) => {
	if (Array.isArray(ids) && ids.length) {
		await readNotifications(ids)
	}
}

// sockets: {
//   notification: {
//     connect(evt) {
//       this.dispatchedUser.then((user) => {
//         this.$socket.notification.emit('join', { userId: this.profile.id });
//       });
//     },
//     notification(notify) {
//       if (this.notifications.length >= this.searchFilter.perPage) {
//         this.notifications.pop();
//       }
//       this.notifications.unshift(notify);
//
//       // Если уведомленияя приходя на эту страницу, то убираем непрочитанные
//       setTimeout(() => {
//         this.$store.commit('SET_NOTIFICATIONS_COUNT', 0);
//       }, 250);
//     },
//     delete_notify(ids) {
//       if (ids === 'all') {
//         this.notifications = [];
//       } else {
//         this.notifications = this.notifications.filter(notify => !ids.includes(notify.id));
//       }
//     }
//   }
// },

const created = () => {
	loadNotifications()
	store.commit('SET_NOTIFICATIONS_COUNT', 0)
	readAll()
}

created()
</script>

<style>
.container:not(.max).notifications-container {
  max-width: 1100px;
}
</style>

<style lang="scss" scoped>
.v-link {
	cursor: pointer;
	text-decoration: underline;
	color: #009ee0;

	&.disabled {
		cursor: default;
		color: #757575;
		text-decoration: none;
		opacity: 0.3;
	}

	&:hover:not(.disabled) {
		opacity: 0.6;
	}

	&:active:not(.disabled) {
		opacity: 1;
		color: #0086be;
	}
}

.link {
	cursor: pointer;
	text-decoration: underline;
}
.notify-row {
	display: flex;
	padding: 15px;
	justify-content: space-between;
	align-items: center;
	transition: all 0.2s linear;
	background-color: #fff;
	border-radius: 10px;
	margin-bottom: 5px;
	//cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	position: relative;

	&:hover {
		box-shadow: 0 3px 14px 3px rgba(0, 0, 0, 0.23);
	}
}

.close {
	display: block;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	border: 1px solid #000;
	cursor: pointer;
	transition: all 0.3s;
	opacity: 0.3;

	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(150%, -50%);
}

.close:hover {
	opacity: 1;
}

.close:after,
.close:before {
	position: absolute;
	left: 9px;
	top: 3px;
	content: ' ';
	height: 14px;
	width: 2px;
	background-color: #333;
}

.close:before {
	transform: rotate(45deg);
}

.close:after {
	transform: rotate(-45deg);
}
</style>

<style lang="scss">
.icon-blue path {
	fill: #009ee0 !important;
	stroke: #009ee0 !important;
}
</style>
