<template>
	<AssessmentVocabularyHeader
		:index="index"
		:question="question"
		:fromAssessment="fromAssessment"
		:currentWords="currentWords"
		:currentLessonProgressData="currentLessonProgressData"
	/>
	<div v-if="currentWords && currentLessonProgressData" class="v-match-wrapper" :class="{ disabled: isDisabled }">
		<div class="w-full flex justify-center items-center mt-2 mb-3 v-text--desc">
			<AppSvgIcon class="mr-2" size="20" name="info" />
			<div style="margin-top: 1px" class="vzn-info__text">{{ $t('lesson.pair_yellow_cards') }}</div>
		</div>

		<template v-if="!isTrueMobile">
			<!-- Mode with diagram -->
			<div class="v-match w-full flex" v-if="currentLesson.interactive_image">
				<div
					class="v-diagram mr-10 relative"
					:style="{ transform: `translateY(${overScroll > 0 ? overScroll : 0}px)` }"
				>
					<img
						:src="currentLesson.interactive_image"
						alt=""
						ref="diagram"
						@load="onImageLoad"
						style="max-width: 100%"
					/>
					<div
						class="v-diagram__sign absolute"
						:class="{
							active: currentSelectedWord && word.id === currentSelectedWord.id,
							right: (right && isWordSet(word, 0)) || isRight(word),
							wrong: wrong && isWordSet(word, 0),
						}"
						:style="{
							top: (word.coordinates ? word.coordinates[1] : 0) + 'px',
							left: (word.coordinates ? word.coordinates[0] : 0) + 'px',
						}"
						v-for="(word, wordIndex) in currentWords"
						:key="word.id"
					></div>
				</div>
				<div class="w-1/2 flex flex-wrap" ref="words" style="min-width: 142px">
					<div class="w-1/2 px-2 my-2" v-for="word in shuffledWords" :key="'one' + word.id">
						<div
							class="v-game-card v-game-card--adapted v-clickable"
							style="width: 100%"
							ref="wordCard"
							@click="selectWord(word, 1)"
							:class="{
								selected:
									!wrong &&
									currentSelectedWord &&
									currentSelectedWord.id === word.id &&
									currentSelectedSide === 1,
								wrong: wrong && isWordSet(word, 1),
								right: (right && isWordSet(word, 1)) || isRight(word),
							}"
						>
							<WordCard :showPinyin="true" :word="word" :illustration="false" />
						</div>
					</div>
				</div>
			</div>

			<!-- Mode without translation -->
			<div class="v-match w-full flex" v-else-if="currentLesson.without_translation">
				<div class="w-1/4">
					<div
						class="v-game-card v-game-card--adapted mb-2"
						style="height: auto; width: 100%"
						v-for="word in shuffledWords"
						:key="'one' + word.id"
						@click="selectWord(word, 0)"
						:class="{
							selected:
								!wrong &&
								currentSelectedWord &&
								currentSelectedWord.id === word.id &&
								currentSelectedSide === 0,
							wrong: wrong && isWordSet(word, 0),
							right: (right && isWordSet(word, 0)) || isRight(word),
						}"
					>
						<WordCard :showPinyin="true" :word="word" :illustration="false" />
					</div>
				</div>
				<div class="w-3/4 flex flex-wrap">
					<div class="w-1/3 px-2 mb-3" v-for="word in shuffledWordsTranslate" :key="'two' + word.id">
						<div
							class="v-game-card v-game-card--adapted v-match__card"
							ref="wordCard"
							style="width: 100%"
							@click="selectWord(word, 1)"
							:class="{
								selected:
									currentSelectedWord &&
									currentSelectedWord.id === word.id &&
									currentSelectedSide === 1,
								wrong: wrong && isWordSet(word, 1),
								right: (right && isWordSet(word, 1)) || isRight(word),
							}"
						>
							<WordCard
								:showPinyin="true"
								:word="word"
								translate
								coverback
								:coverOpacity="false"
								cnPinyin
							/>
						</div>
					</div>
				</div>
			</div>

			<!-- Mode without images -->
			<div class="v-match w-full flex" v-else-if="!imageMode">
				<div class="w-1/2 flex flex-wrap">
					<div class="w-1/2 px-2 my-2" v-for="word in shuffledWords" :key="'one' + word.id">
						<div
							class="v-game-card v-game-card--adapted v-clickable"
							ref="wordCard"
							style="width: 100%"
							@click="selectWord(word, 0)"
							:class="{
								selected:
									!wrong &&
									currentSelectedWord &&
									currentSelectedWord.id === word.id &&
									currentSelectedSide === 0,
								wrong: wrong && isWordSet(word, 0),
								right: (right && isWordSet(word, 0)) || isRight(word),
							}"
						>
							<WordCard :showPinyin="true" :word="word" translate :illustration="false" cnPinyin />
						</div>
					</div>
				</div>
				<div class="w-1/2 flex flex-wrap">
					<div class="w-1/2 px-2 my-2" v-for="word in shuffledWordsTranslate" :key="'two' + word.id">
						<div
							class="v-game-card v-game-card--adapted v-clickable"
							ref="wordCard"
							@click="selectWord(word, 1)"
							style="width: 100%"
							:class="{
								selected:
									currentSelectedWord &&
									currentSelectedWord.id === word.id &&
									currentSelectedSide === 1,
								wrong: wrong && isWordSet(word, 1),
								right: (right && isWordSet(word, 1)) || isRight(word),
							}"
						>
							<WordCard :showPinyin="true" :word="word" :illustration="false" />
						</div>
					</div>
				</div>
			</div>

			<!-- Mode with images -->
			<div class="v-match w-full flex" v-else-if="imageMode">
				<div class="w-1/4">
					<div
						class="v-game-card v-game-card--adapted mb-2"
						ref="wordCard"
						style="height: auto; width: 100%"
						v-for="word in shuffledWords"
						:key="'one' + word.id"
						@click="selectWord(word, 0)"
						:class="{
							selected:
								!wrong &&
								currentSelectedWord &&
								currentSelectedWord.id === word.id &&
								currentSelectedSide === 0,
							wrong: wrong && isWordSet(word, 0),
							right: (right && isWordSet(word, 0)) || isRight(word),
						}"
					>
						<WordCard :showPinyin="true" :word="word" :illustration="false" />
					</div>
				</div>
				<div class="w-3/4 flex flex-wrap">
					<div class="w-1/3 px-2 mb-3" v-for="word in shuffledWordsTranslate" :key="'two' + word.id">
						<div
							class="v-game-card v-game-card--adapted v-match__card"
							ref="wordCard"
							style="width: 100%"
							@click="selectWord(word, 1)"
							:class="{
								selected:
									currentSelectedWord &&
									currentSelectedWord.id === word.id &&
									currentSelectedSide === 1,
								wrong: wrong && isWordSet(word, 1),
								right: (right && isWordSet(word, 1)) || isRight(word),
							}"
						>
							<WordCard :showPinyin="true" :word="word" translate coverback cnPinyin />
						</div>
					</div>
				</div>
			</div>
		</template>

		<template v-else>
			<div class="v-diagram mr-10 relative" v-if="currentLesson.interactive_image">
				<img :src="currentLesson.interactive_image" alt="" ref="diagram" @load="onImageLoad" />
				<div
					class="v-diagram__sign absolute"
					:class="{
						active: currentSelectedWord && word.id === currentSelectedWord.id,
						right: (right && isWordSet(word, 0)) || isRight(word),
						wrong: wrong && isWordSet(word, 0),
					}"
					:style="{
						top: (word.coordinates ? word.coordinates[1] : 0) + 'px',
						left: (word.coordinates ? word.coordinates[0] : 0) + 'px',
					}"
					v-for="(word, wordIndex) in currentWords"
					:key="word.id"
				></div>
			</div>
			<div v-if="currentLesson.interactive_image" class="v-match w-full flex">
				<div class="w-full flex flex-wrap" ref="words">
					<div class="w-1/2 px-2 my-2" v-for="word in shuffledWords" :key="'one' + word.id">
						<div
							class="v-game-card v-game-card--adapted v-clickable"
							ref="wordCard"
							@click="selectWord(word, 1)"
							style="width: 100%"
							:class="{
								selected:
									!wrong &&
									currentSelectedWord &&
									currentSelectedWord.id === word.id &&
									currentSelectedSide === 1,
								wrong: wrong && isWordSet(word, 1),
								right: (right && isWordSet(word, 1)) || isRight(word),
							}"
						>
							<WordCard :showPinyin="true" :word="word" :illustration="false" />
						</div>
					</div>
				</div>
			</div>
			<div class="v-match w-full flex" v-else>
				<div class="w-1/2 flex flex-wrap">
					<div class="w-full px-2 mb-2" v-for="word in shuffledWords" :key="'one' + word.id">
						<div
							class="v-game-card v-game-card--adapted v-clickable"
							ref="wordCard"
							@click="selectWord(word, 0)"
							style="width: 100%; min-width: 135px"
							:class="{
								selected:
									!wrong &&
									currentSelectedWord &&
									currentSelectedWord.id === word.id &&
									currentSelectedSide === 0,
								wrong: wrong && isWordSet(word, 0),
								right: (right && isWordSet(word, 0)) || isRight(word),
							}"
						>
							<WordCard :showPinyin="true" :word="word" :illustration="false" />
						</div>
					</div>
				</div>
				<div class="w-1/2 flex flex-wrap">
					<div class="w-full px-2 mb-2" v-for="word in shuffledWordsTranslate" :key="'two' + word.id">
						<div
							class="v-game-card v-game-card--adapted v-clickable"
							ref="wordCard"
							style="width: 100%"
							@click="selectWord(word, 1)"
							:class="{
								selected:
									currentSelectedWord &&
									currentSelectedWord.id === word.id &&
									currentSelectedSide === 1,
								wrong: wrong && isWordSet(word, 1),
								right: (right && isWordSet(word, 1)) || isRight(word),
							}"
						>
							<WordCard :showPinyin="true" :word="word" translate coverback cnPinyin />
						</div>
					</div>
				</div>
			</div>
		</template>

		<div class="flex items-center justify-center mt-10 text-gray-500">
			<div class="flex items-center cursor-pointer v-clickable" @click="restart(true)">
				<svg width="20" class="mr-3" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M7.96935 1.33349C8.85367 1.33639 9.72863 1.51464 10.5436 1.85793C11.3586 2.20122 12.0974 2.70273 12.7172 3.33344H10.636C10.4592 3.33344 10.2896 3.40368 10.1646 3.5287C10.0395 3.65372 9.96931 3.82329 9.96931 4.00009C9.96931 4.1769 10.0395 4.34646 10.1646 4.47149C10.2896 4.59651 10.4592 4.66674 10.636 4.66674H13.3979C13.7262 4.66657 14.041 4.53608 14.2731 4.30396C14.5052 4.07183 14.6357 3.75705 14.6359 3.42877V0.666835C14.6359 0.490028 14.5656 0.320463 14.4406 0.195442C14.3156 0.0704203 14.146 0.000183968 13.9692 0.000183968C13.7924 0.000183968 13.6228 0.0704203 13.4978 0.195442C13.3728 0.320463 13.3026 0.490028 13.3026 0.666835V2.05214C12.2007 1.05979 10.8443 0.393781 9.38539 0.12868C7.92643 -0.136421 6.42247 0.00985838 5.04194 0.551136C3.66142 1.09241 2.45882 2.00732 1.56884 3.19339C0.678859 4.37947 0.136627 5.78988 0.00286931 7.26668C-0.00574158 7.35952 0.00507977 7.45313 0.0346439 7.54155C0.064208 7.62998 0.111867 7.71127 0.174583 7.78026C0.237299 7.84925 0.313698 7.90442 0.398912 7.94225C0.484126 7.98008 0.576286 7.99975 0.669521 8C0.832576 8.00208 0.990536 7.94323 1.11248 7.83497C1.23443 7.72671 1.31158 7.57683 1.32884 7.41468C1.47724 5.75522 2.2408 4.21122 3.46951 3.086C4.69822 1.96079 6.30327 1.33568 7.96935 1.33349Z"
						fill="#9C9FAD"
					/>
					<path
						d="M15.2699 8C15.1068 7.99792 14.9488 8.05677 14.8269 8.16503C14.7049 8.27329 14.6278 8.42317 14.6105 8.58532C14.5003 9.85411 14.0285 11.0647 13.251 12.0734C12.4735 13.0821 11.423 13.8466 10.2241 14.2762C9.02515 14.7059 7.72816 14.7826 6.48695 14.4973C5.24575 14.2119 4.11244 13.5766 3.22146 12.6666H5.30275C5.47955 12.6666 5.64912 12.5963 5.77414 12.4713C5.89916 12.3463 5.9694 12.1767 5.9694 11.9999C5.9694 11.8231 5.89916 11.6535 5.77414 11.5285C5.64912 11.4035 5.47955 11.3333 5.30275 11.3333H2.54081C2.37821 11.3332 2.21719 11.3651 2.06696 11.4273C1.91672 11.4895 1.78021 11.5807 1.66524 11.6957C1.55026 11.8106 1.45908 11.9471 1.3969 12.0974C1.33471 12.2476 1.30275 12.4086 1.30284 12.5712V15.3332C1.30284 15.51 1.37308 15.6795 1.4981 15.8046C1.62312 15.9296 1.79268 15.9998 1.96949 15.9998C2.1463 15.9998 2.31586 15.9296 2.44088 15.8046C2.56591 15.6795 2.63614 15.51 2.63614 15.3332V13.9479C3.738 14.9402 5.09436 15.6062 6.55332 15.8713C8.01228 16.1364 9.51623 15.9901 10.8968 15.4489C12.2773 14.9076 13.4799 13.9927 14.3699 12.8066C15.2598 11.6205 15.8021 10.2101 15.9358 8.73332C15.9444 8.64048 15.9336 8.54687 15.9041 8.45844C15.8745 8.37002 15.8268 8.28873 15.7641 8.21974C15.7014 8.15075 15.625 8.09558 15.5398 8.05775C15.4546 8.01992 15.3624 8.00025 15.2692 8H15.2699Z"
						fill="#9C9FAD"
					/>
				</svg>
				<div>{{ $t('lesson.restart') }}</div>
			</div>
			<div class="flex items-center cursor-pointer v-clickable" v-if="false">
				<svg width="20" class="mr-3" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346631 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46143 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.40619 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39252 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76751 14.6647 8 14.6667Z"
						fill="#9C9FAD"
					/>
					<path
						d="M8.478 3.37533C8.09355 3.30529 7.69841 3.32059 7.32053 3.42016C6.94265 3.51973 6.59127 3.70113 6.29126 3.95153C5.99125 4.20194 5.74994 4.51521 5.58441 4.8692C5.41887 5.22318 5.33316 5.60922 5.33334 6C5.33334 6.17681 5.40357 6.34638 5.5286 6.4714C5.65362 6.59643 5.82319 6.66666 6 6.66666C6.17681 6.66666 6.34638 6.59643 6.47141 6.4714C6.59643 6.34638 6.66667 6.17681 6.66667 6C6.6665 5.80385 6.70961 5.61008 6.79293 5.4325C6.87625 5.25493 6.99772 5.09792 7.14868 4.97268C7.29964 4.84745 7.47638 4.75706 7.66628 4.70796C7.85619 4.65887 8.05459 4.65228 8.24733 4.68866C8.51069 4.73979 8.75284 4.86826 8.94283 5.05766C9.13283 5.24706 9.26205 5.4888 9.314 5.752C9.36647 6.02827 9.33024 6.31405 9.2105 6.56849C9.09077 6.82293 8.89365 7.033 8.64733 7.16866C8.23945 7.40497 7.90239 7.74634 7.67128 8.1572C7.44018 8.56806 7.32347 9.03337 7.33333 9.50466V10C7.33333 10.1768 7.40357 10.3464 7.5286 10.4714C7.65362 10.5964 7.82319 10.6667 8 10.6667C8.17681 10.6667 8.34638 10.5964 8.47141 10.4714C8.59643 10.3464 8.66667 10.1768 8.66667 10V9.50466C8.6583 9.27267 8.7112 9.04257 8.82005 8.83753C8.92889 8.63248 9.08983 8.45973 9.28667 8.33666C9.76968 8.07138 10.1586 7.66297 10.4001 7.1676C10.6415 6.67223 10.7235 6.11424 10.6349 5.57035C10.5462 5.02646 10.2912 4.52339 9.905 4.13033C9.51877 3.73726 9.02025 3.47351 8.478 3.37533Z"
						fill="#9C9FAD"
					/>
					<path
						d="M8.66667 12C8.66667 11.6318 8.36819 11.3333 8 11.3333C7.63181 11.3333 7.33333 11.6318 7.33333 12C7.33333 12.3682 7.63181 12.6667 8 12.6667C8.36819 12.6667 8.66667 12.3682 8.66667 12Z"
						fill="#9C9FAD"
					/>
				</svg>
				<div>{{ $t('lesson.hint') }}</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import WordCard from '@/components/WordCard.vue'
import { useStore } from 'vuex'
import { ref, computed, watch, onMounted, onBeforeUnmount, toRefs } from 'vue'
import shuffle from 'lodash/shuffle'
import sample from 'lodash/sample'
import { setFontSizeForChildrenRecursive } from '@/helpers.js'
import useEventBus from '../../../composables/useEventBus.js'
import { isStringEmpty, preparedLessonForAssessment, updateFontSize } from '@/helpers.js'
import AssessmentCommentChat from '@/components/assessmnet-comments/chat/AssessmentCommentChat.vue'
import AssessmentVocabularyHeader from '@/screens/AssessmentVocabularyHeader.vue'
import AppSvgIcon from '@/components/helperComponents/AppSvgIcon.vue'

const props = defineProps({
	fromAssessment: Boolean,
	question: {},
	index: {
		type: Number,
		default: 0,
	},
})

const emit = defineEmits(['clear-error', 'set-error'])

const emitter = useEventBus()
const store = useStore()

const shuffledWords = ref(null)
const shuffledWordsTranslate = ref(null)
const currentSelectedWord = ref(null)
const currentSelectedSide = ref(null)
const selectTimeout = ref(null)
const secondSelectedWord = ref(null)
const secondSelectedSide = ref(null)
const wrong = ref(false)
const right = ref(false)
const GAME_SLUG = 'find_word'
const overScroll = ref(0)
const imageLoaded = ref(false)
const imageWidth = ref(0)
const imageHeight = ref(0)

const preparedWords = computed(() => preparedLessonForAssessment(props.question))

const currentLesson = computed(() => store.state.lessons.currentLesson)
const currentWords = computed(() => (props.fromAssessment ? preparedWords.value : store.state.lessons.currentWords))
const currentLessonProgressData = computed(() => store.state.lessons.currentLessonProgressData[GAME_SLUG])
const isTrueMobile = computed(() => window.isTrueMobile)
const imageMode = computed(() => currentWords.value && currentWords.value.some((word) => word.illustration))
const getReversedWords = computed(() =>
	Array.from([...currentWords.value].reverse()).map((el, i) => ({ ...el, index: i + 1 }))
)
const isDisabled = computed(() => right.value || wrong.value)
const getCustomQuestionOrder = computed(() => {
	return props.question.size && parseInt(props.question.size[0]) > 0 ? { order: 1 } : { order: 0 }
})

const getTestData = () => {
	isInvalid.value = false
	emit("clear-error", props.question.id);

	const valid = currentLessonProgressData.value.words?.length === currentWords.value?.length
	if (!valid) {
		isInvalid.value = true
		emit("set-error", props.question.id);
	}

	return {
		answers_pair: currentLessonProgressData.value.words?.length
			? currentWords.value.map((el) => [el.id, el.translateId])
			: [],
		type: 'lexical_find_a_pair',
		id: props.question.id,
		empty: !valid,
	}
}

const isInvalid = ref(false)
defineExpose({ getTestData })

function onImageLoad(evt) {
	imageLoaded.value = true
	imageWidth.value = evt.target.width
	imageHeight.value = evt.target.height
	registerObserver(evt.target)
	recalculateCoordinates(currentLesson.value.interactive_image_width)
}

function setFontSize() {
	const wordCards = document.querySelectorAll('.v-word__word')
	wordCards.forEach((card) => {
		let defaultFontSize = 20
		while (card.scrollHeight > card.clientHeight && defaultFontSize > 0) {
			defaultFontSize--
			card.style.fontSize = `${defaultFontSize}px`
			setFontSizeForChildrenRecursive(card, `${defaultFontSize}px !important; word-break: break-word;`)
		}
	})
}

function registerObserver(target) {
	if (window.ResizeObserver) {
		const observer = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				if (imageWidth.value !== entry.contentRect.width) {
					imageWidth.value = entry.contentRect.width
					imageHeight.value = entry.contentRect.height
					setTimeout(() => recalculateCoordinates(currentLesson.value.interactive_image_width), 500)
				}
			})
		})
		observer.observe(target)
	}
}

function recalculateCoordinates(baseWidth) {
	const coef = imageWidth.value / baseWidth / 0.5
	store.commit('RECALCULATE_COORDINATES', { coef })
}

function onScroll(data) {
	const diagramHeight = document.querySelector('#diagram')?.clientHeight || 0
	const wordsHeight = document.querySelector('#words')?.clientHeight || 0
	const scrollOffset = data[0]?.target.scrollTop - 61 || 0

	if (wordsHeight > diagramHeight && scrollOffset + diagramHeight < wordsHeight) {
		overScroll.value = scrollOffset
	}
}

function initGame() {
	if (!shuffledWords.value && currentWords.value) {
		shuffledWords.value = shuffle(currentWords.value)
		shuffledWordsTranslate.value = shuffle(shuffledWords.value)
	}
	randomSelectedWord()
}

function isWordSet(word, side) {
	return (
		(secondSelectedWord.value?.id === word.id && secondSelectedSide.value === side) ||
		(currentSelectedWord.value?.id === word.id && currentSelectedSide.value === side)
	)
}

function isRight(word) {
	return currentLessonProgressData.value?.words.includes(word.id)
}

function randomSelectedWord() {
	const side = currentLesson.value.interactive_image ? 0 : Math.random() >= 0.5 ? 1 : 0
	const sourceArr = [shuffledWords.value, shuffledWordsTranslate.value][side]
	currentSelectedWord.value = sample(sourceArr.filter((word) => !isRight(word)))
	currentSelectedSide.value = side
}

function selectWord(word, side) {
	if (side === currentSelectedSide.value || secondSelectedWord.value) return

	secondSelectedWord.value = word
	secondSelectedSide.value = side

	if (word.id !== currentSelectedWord.value.id) {
		toggleWrong()
	} else {
		toggleRight()
	}
}

const isNovakid = computed(() => store.getters.isNovakid)

function toggleWrong() {
	clearTimeout(selectTimeout.value)
	wrong.value = true
	selectTimeout.value = setTimeout(() => {
		store.commit('SAVE_TIP', { wordId: currentSelectedWord.value.id, gameName: GAME_SLUG })
		wrong.value = false
		secondSelectedWord.value = null
		randomSelectedWord()
	}, 700)
}

async function toggleRight() {
	clearTimeout(selectTimeout.value)

	if (currentSelectedWord.value.audio) {
		const audio = new Audio(currentSelectedWord.value.audio)
		try {
			await audio.play()
		} catch (err) {
			console.error('audio play error', err)
		}
	}

	right.value = true
	selectTimeout.value = setTimeout(() => {
		store.commit('SAVE_WORD', { gameName: GAME_SLUG, wordId: currentSelectedWord.value.id })
		right.value = false
		secondSelectedWord.value = null
		randomSelectedWord()

		if (currentWords.value.every((word) => isRight(word))) {
			reset()
			// window.dispatchEvent(new CustomEvent))
			emitter.emit('v-task-result', { gameName: GAME_SLUG })
		}
	}, 700)
}

function restart() {
	store.commit('RESTART_TASK', { gameName: GAME_SLUG })
	reset()
}

function reset() {
	shuffledWords.value = null
	shuffledWordsTranslate.value = null
	currentSelectedWord.value = null
	currentSelectedSide.value = null
	selectTimeout.value = null
	secondSelectedWord.value = null
	secondSelectedSide.value = null
	wrong.value = false
	right.value = false
	initGame()
}

onMounted(() => {
	initGame()
	setTimeout(setFontSize, 300)
	setTimeout(() => updateFontSize('.v-word__word'), 1000)
})

watch(
	currentLessonProgressData,
	(value) => {
		if (value && value.words.length === 0) {
			initGame()
		}
	},
	{ deep: true }
)
</script>

<style lang="scss" scoped>
.v-match-wrapper {
	&.disabled {
		pointer-events: none;
	}
}

.v-match__card {
	height: 100% !important;
	padding: 0 !important;
}

.v-game-card {
	position: relative;
	border: 2px solid #d9dce6 !important;
	box-shadow: none !important;

	&.selected {
		border: 6px solid rgba(243, 212, 6, 1) !important;
		animation: pulse 2s infinite;
		@keyframes pulse {
			0% {
				box-shadow: 0 0 0 0 rgba(243, 212, 6, 0.4);
			}
			70% {
				box-shadow: 0 0 0 20px rgba(243, 212, 6, 0);
			}
			100% {
				box-shadow: 0 0 0 0 rgba(243, 212, 6, 0);
			}
		}

		// &::after {
		//   content: '';
		//   position: absolute;
		//   top: 0;
		//   left: 0;
		//   width: 100%;
		//   height: 100%;
		//   background-color: rgba(243, 212, 6, 0.5);
		// }
	}

	&.wrong {
		background-color: #ec591d !important;
	}

	&.right {
		background-color: #1fc05d !important;
		pointer-events: none !important;
		color: #fff;
	}

	//& .v-word__word {
	//  word-break: break-word;
	//}
}
</style>
