<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue'

const props = defineProps({
	options: {
		type: Array,
		default: () => [{ key: 0, value: 'Показать все' }],
	},
	selected: {
		type: [Number, String],
		default: 0,
	},
	selectKey: {
		type: String,
		default: 'key',
	},
	selectValue: {
		type: String,
		default: 'value',
	},
	green: Boolean,
	gray: Boolean,
	center: Boolean,
	mobile: Boolean,
})

const emit = defineEmits(['update:selected', 'select', 'expanded'])

const expanded = ref(false)
const preventBlur = ref(false) // Флаг для предотвращения закрытия при клике на список

const currentSelected = computed({
	get: () => props.selected,
	set: (value) => {
		emit('update:selected', value)
		emit('select')
	},
})

const getCurrentItem = computed(() => {
	return props.options.find((el) => el[props.selectKey] == currentSelected.value) || {}
})

const toggleDropdown = () => {
	expanded.value = !expanded.value
	emit('expanded', expanded.value)
}

// Обрабатываем `mousedown` для предотвращения blur
const handleMouseDown = () => {
	preventBlur.value = true
}

const handleBlur = () => {
	if (preventBlur.value) {
		preventBlur.value = false // Сбрасываем флаг
		return
	}
	expanded.value = false
	emit('expanded', false)
}

const selectItem = (item) => {
	currentSelected.value = item[props.selectKey]
	expanded.value = false
	emit('expanded', false)
}
</script>

<template>
	<span class="v-dropdown" :class="{ green, mobile, gray }">
		<div
			class="v-dropdown__main flex justify-between items-center relative"
			:class="{ expanded }"
			tabindex="0"
			@blur="handleBlur"
			@click="toggleDropdown"
		>
			<div :class="center ? 'mx-auto' : ''">
				<shortify class="mr-2" :value="getCurrentItem[selectValue]" :length="12" />
			</div>
			<svg :class="{ expanded }" width="15.5px" height="9.5px">
				<path
					fill-rule="evenodd"
					stroke="#141029"
					stroke-width="1px"
					stroke-linecap="butt"
					stroke-linejoin="miter"
					fill="#141029"
					d="M13.174,1.833 C12.997,1.664 12.710,1.663 12.533,1.833 L7.821,6.332 C7.645,6.501 7.356,6.501 7.179,6.332 L2.467,1.833 C2.290,1.663 2.003,1.664 1.826,1.833 C1.649,2.003 1.650,2.278 1.826,2.447 L6.538,6.946 C6.803,7.200 7.152,7.327 7.500,7.327 C7.848,7.327 8.197,7.200 8.462,6.946 L13.174,2.447 C13.352,2.278 13.352,2.003 13.174,1.833 Z"
				/>
			</svg>
		</div>

		<div class="v-dropdown__list" v-show="expanded && !mobile" @mousedown="handleMouseDown">
			<div
				class="v-dropdown__item"
				v-for="(item, index) in options"
				:key="item[selectKey]"
				@click="selectItem(item)"
			>
				{{ item[selectValue] }}
			</div>
		</div>
	</span>
</template>

<style lang="scss" scoped>
.v-dropdown {
	width: 100%;
	max-width: 300px;
	position: relative;
	z-index: 10;

	&.mobile {
		max-width: unset;
	}

	&.gray {
		color: #959595;
	}

	&.gray &__main {
		border-color: #959595;
		border-width: 1px;
	}

	&.green {
		color: #27a34c;
	}

	&.green &__main {
		border-color: #27a34c !important;

		&.expanded {
			background: #27a34c;
		}
	}

	&.green &__list {
		border-color: #27a34c !important;
	}

	&.green &__item {
		&:hover {
			background: #27a34c;
		}
	}

	&__main {
		border-radius: 8px;
		padding: 8px 15px;
		background-color: #f8f8fa;
		border: 1px solid transparent;
		cursor: pointer;
		z-index: 2;
		position: relative;

		&.expanded {
			background-color: #ffffff;
			border: 1px solid #4579f5;
			color: #141029;
		}
	}

	&__list {
		position: absolute;
		top: 0;
		left: 0;
		min-width: 100%;
		max-width: 300px;
		border: 2px solid #f0f1f5;
		padding-top: 40px;
		border-radius: 22px;
		background: #fff;
		animation: show 0.2s linear;
		animation-fill-mode: forwards;
		overflow: hidden;

		max-height: 300px;
		overflow: auto;

		@keyframes show {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}
	}

	&__item {
		padding: 5px 30px;
		cursor: pointer;
		transition: all 0.2s linear;
		position: relative;

		&:hover {
			color: #4579f5;
			background: #ecf7ff;
		}
	}
}

.discount-line {
	position: relative;

	&::after {
		position: absolute;
		top: 11px;
		left: -8px;
		width: 79%;
		height: 2px;
		background-color: red;
		transform: rotate(0deg);
		content: '';
	}
}

.discount-text {
	color: red;
	font-weight: bold;
	display: inline-block;
	text-align: center;
}

svg {
	transition: all 0.3s linear;
	&.expanded {
		transform: rotate(180deg);
	}
	&.expanded path {
		stroke: #141029;
	}
}

.v-price {
	position: relative;

	&__count {
		position: relative;
		&.discount::after {
			content: '';
			width: 120%;
			left: -10%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			height: 2px;
			background-color: red;
		}
	}

	&__discount {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateX(94%) translateY(-30%);
		width: 115px;
		font-size: 13px;
		text-align: center;

		div:nth-child(1) {
			font-size: 16px;
			line-height: 1;
			font-weight: 200;
		}
		div:nth-child(2) {
			font-size: 12px;
			line-height: 1;
			font-weight: 200;
		}
	}
}
</style>
