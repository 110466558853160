<template>
	<div class="v-header" :class="{ preview: previewMode }">
		<div class="preview-warning" v-if="previewMode">{{ $t('home.preview_mode') }}</div>
		<div class="container mx-auto h-full flex items-center justify-between">
			<router-link :to="{ name: 'home' }" class="outline-none" v-if="loaded && !getPlatform">
				<img
					class="v-header__logo outline-none"
					:style="{ height: '70px' }"
					src="../assets/logo_new_ru.png"
					alt="logo"
				/>
			</router-link>
			<router-link :to="{ name: 'home' }" class="outline-none" v-else-if="getPlatform">
				<img
					class="v-header__logo outline-none"
					:style="{ height: '40px' }"
					src="../assets/logo_new_ru.png"
					alt="logo"
				/>
			</router-link>
			<div
				class="flex items-center"
				v-if="isProfileLoaded && !getPlatform && profile.platform_id !== 16 && profile.platform_id !== 17"
			>
				<!--        <div class="v-header__btn v-clickable cursor-pointer mx-3 text-sm" @click="$root.$emit('donate')">-->
				<!--          <img class="mr-3" src="/images/handshake.svg" alt="">-->
				<!--          Donate-->
				<!--        </div>-->
				<div class="v-header__btn v-clickable cursor-pointer mx-3 ml-0 text-sm" @click="emitter.emit('review')">
          <IconDisplay iconPath="/images/Header/message-question.svg" />
					{{ $t('home.what_imporve') }}
				</div>
			</div>
			<div class="flex items-center">
				<div v-if="isProfileLoaded" class="flex items-center">
					<router-link :to="{ name: 'home' }" class="mx-3 router-item" exact>
						<IconDisplay iconPath="/images/Header/home-new.svg" customClass="v-header__button" />
						<div class="tooltip">{{ $t('home.my_sets') }}</div>
					</router-link>

					<router-link :to="{ name: 'chat' }" class="mx-3 router-item" exact>
						<IconDisplay iconPath="/images/Header/messages-new.svg" customClass="v-header__button" />
						<div class="tooltip">{{ $t('messages.messages') }}</div>
						<div class="unread-count" v-if="chatController && chatController.unreadCount">
							{{ chatController.unreadCount }}
						</div>
					</router-link>

					<router-link :to="{ name: 'statistics' }" class="mx-3 router-item" exact>
						<IconDisplay iconPath="/images/Header/statistics-new.svg" customClass="v-header__button" />
						<div class="tooltip">{{ $t('statistics.statistics') }}</div>
					</router-link>

					<!--          <router-link v-if="!getPlatform" :to="{ name: 'marathon' }" class="mx-3 router-item stroke" exact>-->
					<!--              <img v-svg-inline class="v-header__button stroke" src="/images/Header/marathon.svg" />-->
					<!--              <div class="tooltip">{{ $t('home.marathon_label') }}</div>-->
					<!--          </router-link>-->

					<router-link
						v-if="showNotifications"
						:to="{ name: 'notifications' }"
						class="mx-3 router-item"
						exact
					>
						<IconDisplay iconPath="/images/Header/notification-new.svg" customClass="v-header__button" />
						<div class="tooltip">{{ $t('home.notifications') }}</div>
						<div class="message-counter" v-if="notificationsCount">{{ notificationsCount }}</div>
					</router-link>

					<!-- <router-link :to="{ name: 'profile' }" class="mx-4 router-item" exact>
			  <img v-svg-inline class="v-header__button" src="/images/Header/profile.svg" />
			  <div class="tooltip">{{ 'Profile' }}</div>
			</router-link> -->
					<div class="mx-3 router-item" :class="{ 'router-link-exact-active': route.name === 'profile' }">
						<IconDisplay iconPath="/images/Header/user-new.svg" customClass="v-header__button" />
						<div class="tooltip tooltip__profile">
							<!--							<router-link :to="{ name: 'profile' }">{{ $t('home.profile') }}</router-link>-->
							<!--							<a style="padding: 5px 0" v-if="profile.invited" :href="teacherUrl + '/?token=' + token"-->
							<!--								>Учитель</a-->
							<!--							>-->
							<!--              <router-link v-if="loaded && !getPlatform" :to="{ name: 'tariffs' }" style="padding: 5px 0">{{ $t('home.tariffs') }}</router-link>-->
							<a class="logOut" @click="exit()">{{ $t('home.logout') }}</a>
						</div>
					</div>
				</div>
				<div v-else-if="previewMode">
					<div
						class="vzn-button mr-3"
						:class="{ 'v-button--novakid': getPlatform && isNovakid }"
						style="box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.15)"
						@click="
							/*isTeacher ?*/ emitter.emit(
								'temp-teacher-groups'
							) /*: $root.$emit('auth', { onlyteacher: true, copy: true })*/
						"
					>
						{{ $t('home.copy_lesson') }}
					</div>
				</div>
				<div v-else-if="!isProfileLoaded">
					<div class="v-button v-button--gradient-red" @click="emitter.emit('auth', { forSaveTitle: true })">
						{{ $t('home.log_in') }}
					</div>
				</div>

				<div
					v-if="showLanguageSelect"
					class="mx-4 router-item lang-switch cursor-pointer"
					@click="showLangSwitch ^= true"
				>
					<div class="lang-switch__current v-text--gray text-xl">{{ getCurrentLang.label }}</div>
					<div class="tooltip tooltip__profile">
						<a v-for="lang in languagesLocales" :key="lang.key" @click="handleLanguage(lang)">{{
							lang.name
						}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import useEventBus from '../composables/useEventBus.js'
import homeIcon from '@/assets/Header/home.svg'
import groupIcon from '@/assets/Header/group.svg'
import messagesIcon from '@/assets/Header/messages.svg'
import statisticsIcon from '@/assets/Header/statistics.svg'
import { clearLocalStorageButLessonTimers } from '@/helpers.js'
import IconDisplay from '@/components/IconDisplay.vue'

onMounted(() => {
	console.log('SVG Inline plugin should be applied to the image')
})

const PLATFORM_NOVAKID_ID = 16

const store = useStore()
const route = useRoute()
const emitter = useEventBus()
const { t, locale } = useI18n()

const loaded = ref(true)
const showLanguageSelect = ref(true)
const showLangSwitch = ref(false)
const currentLanguageKey = ref(null)
const notificationsCount = computed(() => store.state.user.notificationsCount)
const profile = computed(() => store.state.user.profile)
const chatController = computed(() => store.state.user.chatController)
const getPlatform = computed(() => store.getters.getPlatform)
const isProfileLoaded = computed(() => store.getters.isProfileLoadedFullCheck)
const previewMode = computed(() => !!route.query.onlypreview || (route.query.slug && !route.query.group_id))
const showNotifications = computed(
	() => profile.value.platform_id !== PLATFORM_NOVAKID_ID && profile.value.platform_id !== 17
)
const navLinks = ref([
	{ name: 'home', icon: homeIcon, label: 'home.my_sets' },
	{ name: 'groups', icon: groupIcon, label: 'groups.groups' },
	{ name: 'chat', icon: messagesIcon, label: 'messages.messages' },
	{ name: 'statistics', icon: statisticsIcon, label: 'statistics.statistics' },
])

const getCurrentLang = computed(() => languagesLocales.value.find((el) => el.key === currentLanguageKey.value) || {})
const languagesLocales = ref([
	{ name: 'Русский', label: 'Ru', key: 'ru' },
	{ name: 'English', label: 'En', key: 'en' },
])

const handleLanguage = async (lang) => {
	await store.dispatch('SET_USER_LOCALE', lang.key)
	locale.value = lang.key
	currentLanguageKey.value = lang.key
}

const exit = () => {
	clearLocalStorageButLessonTimers()
	window.location.href = import.meta.env.VITE_APP_URL
}

watch(
	() => route.name,
	async (newRoute, oldRoute) => {
		await nextTick()
		const routes = ['games', 'lesson']
		if (
			(routes.includes(newRoute) && !routes.includes(oldRoute)) ||
			(!routes.includes(newRoute) && routes.includes(oldRoute))
		) {
			loaded.value = false
			setTimeout(() => {
				loaded.value = true
			}, 100)
		}
	}
)

// Инициализация языка при создании компонента
onMounted(() => {
	handleLanguage({ key: profile.value.locale || 'ru' })
	if (getPlatform.value && getPlatform.value.domain.includes('vznaniya.com')) {
		handleLanguage({ key: 'en' })
	}
})
</script>

<style lang="scss" scoped>
.preview-warning {
	width: 100%;
	height: 25px;
	background-color: #009fe3;
	text-align: center;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}
</style>

<style lang="scss">
@import '../styles/variables.scss';

.message-counter {
	color: #fff;
	background-color: #e76645;
	width: 20px;
	height: 17px;
	border-radius: 20px;
	font-size: 10px;
	font-weight: 700;
	text-align: center;
	-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	position: absolute;
	right: -5px;
	top: -5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.lang-switch {
	&__current {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 30px;
		line-height: 1;
	}
}

.unread-count {
	position: absolute;
	top: -5px;
	right: -5px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	color: #fff;
	background: #cf6e49;
	padding-top: 2px;
	border: 1px solid #fff;
}

.v-header {
	width: 100%;
	height: 65px;
	background-color: #fff;
	position: relative;
	z-index: 99;

	&.preview {
		height: 90px;

		.container {
			height: calc(100% - 25px) !important;
		}
	}

	&__btn {
		background-color: #ECF7FF;
		border-radius: 8px;
		padding: 10px 20px;
		display: flex;
    gap: 5px;
		align-items: center;
		height: 40px;
    color: #4579F5;
    font-weight: 500;

		img {
			height: 100%;
		}

		svg {
			height: 100%;
		}
	}

	.router-link-exact-active {
		path {
			fill: $vblue;
		}

		&.stroke path {
			stroke: $vblue;
		}
	}

	&__logo {
		height: 40px;
		width: 200px;
		object-fit: contain;
	}

	&__button {
		cursor: pointer;
		height: 25px;
		width: 25px;

		&:focus,
		&:active {
			outline: none;
			border: none;
		}

		path {
			transition: 0.25s linear;
		}

		&:hover path {
			fill: $vblue !important;
		}

		&.stroke:hover path {
			stroke: $vblue !important;
		}
	}
}

.router-item {
	position: relative;
	&:hover .tooltip,
	&:hover .tooltip__profile {
		opacity: 1;
		transform: translateX(-50%) translateY(-10px);
		visibility: visible;
	}
}

.tooltip {
	display: flex;
	justify-content: center;
	position: absolute;
	top: 50px;
	left: 50%;
  background-color: #fff;
  box-shadow: 0px 3px 14px 3px rgba(0, 0, 0, 0.15);
	transform: translateX(-50%);
	border-radius: 15px;
	margin: 0 auto;
	padding: 9px 16px;
	font-size: 14px;
	color: #000;
	text-align: center;
	font-weight: 500;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;
	z-index: 10;
	&::before {
		content: '';
		position: absolute;
		top: -6px;
		left: 50%;
		transform: translateX(-50%);
		width: 0;
		height: 0;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-bottom: 10px solid #fff;
	}
}

.tooltip__profile {
	flex-direction: column;
	align-items: flex-start;
	gap: 5px;
	width: auto;
	a {
		cursor: pointer;

		&:hover {
			color: #4579f5;
		}
	}
}
</style>
