export default {
	сompleted_percent: 'выполнен на {p1}%',
	words: 'Слова',
	words_count: 'слов',
	tasks: 'Задания',
    task_not_completed: 'Задание не выполнено',

	learn_word: 'Проверь себя',
	listen: 'Послушай',
	find_word: 'Найди пару',
	snake: 'Змейка',
	scrabble: 'Скрэмбл',
	quiz: 'Квиз',
	remember: 'Запомни',
	listen_type: 'Послушай и впиши',
	find_words: 'Найди слово',
	find_definition: 'Найди определение',
	write_word: 'Введи слова',
	craft_answer: 'Собери ответ',
	insert_missed: 'Заполни пропуски',
	final_test: 'Финальный тест',

	learn_word_short: 'Проверь',
	listen_short: 'Послушай',
	find_word_short: 'Найди пару',
	snake_short: 'Змейка',
	listen_type_short: 'Послушай - впиши',
	remember_short: 'Запомни',
	quiz_short: 'Квиз',
	message: 'Письмо',
	find_words_short: 'Найди слово',
	find_definition_short: 'Найди определение',
	scrabble_short: 'Скрэмбл',
	write_word_short: 'Введи слова',
	insert_missed_short: 'Пропуски',
	final_test_short: 'Тест',

	test_closed: 'доступ закрыт - выполни все задания)',
	complete_all: 'Выполните все задания',
	test_open: '(доступ открыт)',
	test: 'Тест',

	translation: 'Перевод',
	word: 'Слово',
	audio: 'Аудио',
	listen_do: 'Слушать',
	date: 'Дата',
	result: 'Результат',
	mistakes: 'Допущенные ошибки',
	no_mistakes: 'Нет ошибок',
	no_test_results: 'Нет результатов теста',
	video_to_complete: 'Для прохождения урока досмотри видео до конца и ответь на все вопросы',
	test_not_avaiable: 'Тест больше не доступен для прохождения',
	enter_text: 'Впишите ваш ответ',

	multiple: 'Тест',
	true_false: 'Верно / Неверно',
	make_up: 'Составь предложение',
	fill_gaps: 'Заполни пропуски',
	fill_gaps_input: 'Впиши в пропуски',
	choose: 'Выбери правильный вариант',
	find_a_pair: 'Найди пару',
	classification: 'Классификация',
	order_of_proposals: 'Порядок предложений',
	open_question: 'Открытый вопрос',
	text: 'Текст',
	image: 'Картинка',
	media: 'Медиафайл',

	order: 'Последовательность',
	voice_record: 'Запись голоса',
	partition: 'Новый раздел',
	mistake: 'Исправь ошибку',
	interactive: 'Интерактивный рабочий лист',
	except: 'Найди лишнее',
	suggestion: 'Предложить механику',
	show_mistakes: 'Ошибки',
	show_mistakes2: 'Показать ошибки',
	result: 'Результат',
	time_test: 'Время на тест',
	not_completed: 'Не выполнено',
	result_after_check: 'Результат принят на проверку',
	teacher_check: 'На проверке',
	fillFields: 'Заполните все поля!',
	learnedWords: 'Выучено слов',
	completeLessons: 'Выполнено уроков',
	avgResult: 'Средний балл',

	teacher_variant: 'Задание',
	student_variant: 'Ответ',
	comments: 'Комментарии',
	press_mic: 'Нажмите микрофон для начала записи или паузы. Нажмите стоп для завершения записи',
	record_limit: 'Ограничение продолжительности записи',
	no_mic: 'Нет доступа к микрофону',
	rec_audio: 'Запишите аудио!',
	finish: 'Завершить',

	open_full_screen: 'Включить полноэкранный режим',
	close_full_screen: 'Выключить полноэкранный режим',
	drag_words_here: 'Перетащите слова сюда',

	lock_game: 'Выполните все задания для открытия теста',

	progress: 'Прогресс',
	lexical_words: 'Слова',
	lexical_tasks: 'Задания',
	lexical_test: 'Тест',
	complete_all_tasks: 'Выполни все задания',
	print: 'Печать',
	back: 'Назад',
	next: 'Далее',
	my_dictionary: 'Мой словарь',
	drag_definition_and_voiceover: 'Перетяни определение и озвучку на термин',
	memorized: 'Запомнил',
	drag_and_drop: 'Удерживайте и тяните',
	restart: 'Перезапуск',
	hint: 'Подсказка',
	connect_voiceover_and_definition: 'Cоедини озвучку и определение',
	connect_voiceover_and_word: 'Cоедини озвучку и слово',
	choose_right_answer: 'Выбери правильный вариант ответа',
	pair_yellow_cards: 'Найди пару к желтой карточке',
	type_what_heard: 'Введите, что слышите',

	comments_chat_title: 'Комментарии',
	comments_chat_messages_placeholder: 'Чтобы начать чат отправьте сообщение',
	comments_input_placeholder: 'Комментарий',

	true: 'Верно',
	false: 'Неверно',
	'not-stated': 'Не указано',

	'waiting-for-connection': 'Ожидание сети',
	'dont-close-page': 'Пожалуйста, не закрывайте и не обновляйте страницу, чтобы сохранить результат',

	draft_deleted: 'Преподаватель изменил задание, пожалуйста, пройдите его заново.',

	access_restricted: 'Доступ ограничен',
	write_teacher_about_access: 'Свяжитесь со своим преподавателем, чтобы продолжить использование платформы',
	request_teacher_to_buy_subscription: 'Здравствуйте. Я не могу открыть Ваш урок, т.к. у Вас не продлена подписка.',
	write_teacher: 'Написать преподавателю',
}
