<template>
	<div v-if="fromAssessment" class="w-full flex mb-5 vzn-assessment__header" :style="getCustomQuestionOrder">
		<div class="flex justify-end items-center gap-3 w-full">

<!--				<div-->
<!--					v-if="!isNovakid && !question.isChildren"-->
<!--					class="mb-4 mr-2 v-text&#45;&#45;bold text-2xl flex items-center"-->
<!--					style="line-height: 0.9"-->
<!--					:class="{ 'v-text&#45;&#45;red v-text&#45;&#45;bold': isInvalid }"-->
<!--				>-->
<!--					{{ index + 1 }}.-->
<!--				</div>-->
				<div class="flex flex-grow items-center gap-2 lexical-comments" v-if="question.description && !isStringEmpty(question.description)">
          <IconDisplay iconPath="/images/comment.svg" />
					<div class="changetext" v-html="question.description.replace(/\n/g, '<br>')"></div>
				</div>

			<div class="vzn-header__progress" v-if="!hideProgress">
				<div class="vzn-progress-bar">
					<ProgressBar :percent="getPercent" type="new" />
				</div>
				<div class="flex justify-between mt-2">
					<div class="vzn-progress-bar__title">{{ fromAssessment ? 'Выполнено' : 'Прогресс' }}</div>
					<div class="vzn-progress-bar__info">
						{{
							$t('games.learnt_out_of_short', {
								p1: currentLessonProgressData?.words?.length ?? 0,
								p2: currentWords?.length,
							})
						}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ProgressBar from '../components/ProgressBar.vue'
import { computed } from 'vue'
import { isStringEmpty } from '@/helpers.js'
import IconDisplay from '@/components/IconDisplay.vue'

const props = defineProps({
	fromAssessment: Boolean,
	question: {},
	index: Number,
	isInvalid: Boolean,
	currentWords: [],
	currentLessonProgressData: {},
	hideProgress: Boolean,
})

const getPercent = computed(() => {
	return (props.currentLessonProgressData?.words?.length / props.currentWords?.length) * 100
})
import { useStore } from 'vuex'

const store = useStore()
const getCustomQuestionOrder = computed(() => {
	return props.question.size && parseInt(props.question.size[0]) > 0 ? { order: 1 } : { order: 0 }
})

const isNovakid = computed(() => store.getters.isNovakid)
</script>
<style lang="scss">
@use '@/styles/variables.scss' as variables;

.vzn-assessment__header {

  .lexical-comments {
    background: variables.$baseGrey;
    padding: 10px;
    border-radius: 8px;
    font-size: 20px;

    svg {
      width: 28px;
      height: 28px;
      flex-shrink: 0;
    }
  }

	.vzn-header__progress {
		// width: 300px;
		align-items: center;
		.vzn-progress-bar {
			width: 100%;
			min-width: 200px;
			height: fit-content;
			border-radius: 64px;
			margin-right: 20px;
		}
	}
}
</style>
